import theme from "../../../../src/themes";
import old_resume_snippet from "../../../../src/assets/old_resume_snippet.jpg";
import old_resume_snippet_monolithic from "../../../../src/assets/old_resume_snippet_monolithic.jpg";
import { Content } from 'resume-react';
import { Work } from 'resume-react';
import { Education } from 'resume-react';
import { Skills } from 'resume-react';
import { Heading } from "resume-react";
import * as React from 'react';
export default {
  theme,
  old_resume_snippet,
  old_resume_snippet_monolithic,
  Content,
  Work,
  Education,
  Skills,
  Heading,
  React
};